<template>
  <div>
    <div class="bordered pb-4">
      <div class="grid-x grid-padding-x pt-1">
        <div class="cell small-2">
          <router-link to="/customisation">
            <img width="12px" src="../../assets/icons/arrow_left.svg" alt="Back" />
          </router-link>
        </div>
        <div class="cell small-8">
          <h2 class="text-center basket-header">Garments</h2>
        </div>
        <div class="cell small-2 text-right">
          <router-link to="/customisation">
            <img width="15px" src="../../assets/icons/red_close.svg" alt="" />
          </router-link>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 text-center">
          <h4 class="basket-subheading">Summary</h4>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <h4 class="basket-subheading">
            Customisation
            <router-link to="/customisation">
              <button class="button rounded-small">
                <img width="20px" src="../../assets/icons/green_print.svg" alt="" />
                +
              </button>
            </router-link>
          </h4>
        </div>
      </div>
      <div
        v-for="(design, index) in designs"
        :key="index"
        class="grid-x grid-padding-x">
        <div class="cell small-12">
          <div class="bordered inner pa-3 mb-5 shadow">
            <div class="grid-x grid-padding-x">
              <div class="cell small-4">
                <p class="mb-2 text-small">Name: {{ design.name }}</p>
              </div>
              <div class="cell small-4">
                <p class="mb-2 text-small">Price: {{ design.price | priceInPounds | currency }}</p>
              </div>
              <div class="cell small-4 text-right">
                <i
                  class="link"
                  @click="deleteDesign(index)">
                  <img width="15px" src="../../assets/icons/red_bin.svg" alt="" />
                </i>&nbsp;
                <button
                  @click="editDesign(design.id)"
                  class="button rounded-small">Edit</button>
              </div>
            </div>
            <div class="grid-x">
              <div class="cell small-3">
                <div class="bg-dk text-white text-small text-center pt-1 pb-1 ma-1 text-squashed">
                  <div class="basket-image-container">
                    <img
                      class="basket-type-image"
                      v-if="design.type === 'Print'"
                      src="../../assets/icons/green_print.svg"
                      alt="Print" />
                    <img
                      class="basket-type-image"
                      v-else
                      src="../../assets/icons/red_embroidery.svg"
                      alt="Print" />
                  </div>
                  <div class="fixed-height-text-box">{{ design.type }}</div>
                </div>
              </div>
              <div class="cell small-3">
                <div class="bg-dk text-white text-small text-center pt-1 pb-1 ma-1 text-squashed">
                  <div class="basket-image-container">
                    <app-position-image :position="design.position" />
                  </div>
                  <div class="fixed-height-text-box">{{ design.position }}</div>
                </div>
              </div>
              <div class="cell small-3">
                <div class="ma-1">
                  <img v-if="design.image" :src="design.image" alt="" />
                  <p v-if="design.text">
                    {{ design.text }}
                  </p>
                </div>
              </div>
              <div class="cell small-3">
                <div class="bg-dk text-white text-small text-center pt-1 pb-1 ma-1 text-squashed">
                  <div class="basket-image-container">
                    <span class="ilb mt-3 text-green text-heavy">{{ design.size }}</span>
                    <img
                      class="mt-4" width="80%" src="../../assets/icons/double_arrow.svg"
                      alt="" />
                  </div>
                  <div class="fixed-height-text-box">Width</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-1">
        <div class="cell small-12">
          <h2>Selected garments:</h2>
        </div>
      </div>
      <div class="grid-x pt-1 pl-2">
        <div class="cell small-11">
          <div class="grid-x pt-1">
            <div class="cell small-2 small-offset-2 text-center">
              <span class="garment-table-heading">Code</span>
            </div>
            <div class="cell small-2 text-center">
              <span class="garment-table-heading">Col</span>
            </div>
            <div class="cell small-2 text-center">
              <span class="garment-table-heading">Size</span>
            </div>
            <div class="cell small-2 text-center">
              <span class="garment-table-heading">Qty</span>
            </div>
            <div class="cell small-2 text-center">
              <span class="garment-table-heading">Single</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(garment, index) in garments"
        :key="`g_${index}`"
        class="grid-x pt-1 pl-2">
        <div class="cell small-11 bordered-alt mb-2">
          <div class="grid-x pt-1">
            <div class="cell small-2 text-center">
            <span class="garment-table-content">{{ garment.short_name }}</span>
          </div>
          <div class="cell small-2 text-center">
            <span
              @click="selectGarment(index)"
              class="garment-table-content link">
              {{ garment.code }}
            </span>
          </div>
          <div class="cell small-2 text-center">
            <span class="garment-table-content">{{ garment.short_colour }}</span>
          </div>
          <div class="cell small-2 text-center">
            <span class="garment-table-content">
              <select
                @change="updateBasket"
                class="tiny"
                style="display: block"
                v-model="garment.size">
                <option
                  :value="size.id"
                  v-for="(size, sizeIndex) in garment.sizes"
                  :key="sizeIndex">
                  {{ size.size }}
                </option>
              </select>
            </span>
          </div>
          <div class="cell small-2 text-center">
            <span class="garment-table-content">
              <select @change="updateBasket" class="tiny" v-model="garment.quantity">
                <option v-for="n in 100" :key="n" :value="n">{{ n }}</option>
              </select>
            </span>
          </div>
          <div class="cell small-2 text-center">
            <span class="garment-table-content price">
              {{ garment.price_each | priceInPounds | currency }}
            </span>
          </div>
          </div>
        </div>
        <div class="cell small-1">
          <div class="pt-1 pl-2">
            <img
              class="link"
              @click="removeGarment(index)"
              style="position: relative; top: -4px; right: 5px"
              width="15px"
              src="../../assets/icons/red_bin.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-4">
        <div class="cell small-8">
          <div
            v-for="(design, dindex) in designs"
            :key="dindex"
            class="bordered-alt mb-2 pa-1">
            <div class="grid-x">
              <div class="cell small-2">
                <span class="garment-table-content">
                  No #{{ (dindex + 1) }}
                </span>
              </div>
              <div class="cell small-4">
                <span class="garment-table-content">
                  {{ design.name }}
                </span>
              </div>
              <div class="cell small-3">
                <span class="garment-table-content">
                  <select class="tiny" disabled>
                    <option>{{ totals.garmentNo }}</option>
                  </select>
                </span>
              </div>
              <div class="cell small-3 text-right">
                <span class="garment-table-content price">
                  {{ design.price | priceInPounds | currency }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="cell small-4">
          <span class="basket-total-ex-vat">
            Total ex VAT - {{ totals.exVat | priceInPounds | currency }}
          </span>
          <span class="basket-total-vat">
              VAT @ 20% - {{ totals.vat | priceInPounds | currency }}
          </span>
          <span class="basket-total-price">
            {{ totals.incVat | priceInPounds | currency }}
            <span class="basket-total-vat-notice">
              Total inc VAT
            </span>
          </span>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-10">
        <div class="cell small-10 small-offset-1">
          <router-link to="/">
            <button class="button ghost expanded">
              Add more garments
            </button>
          </router-link>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-10 small-offset-1">
          <router-link to="/checkout">
            <button class="button rounded expanded">
              <i class="icon shopping-trolley"></i>
              Checkout
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <app-selected-garment
      :productId="selectedProduct"
      @closeSelectedGarment="closeSelectedGarment"
      v-if="selectedProduct > 0" />
    <div class="confirmationBox" v-if="designToDelete !== false">
        <span @click="designToDelete = false" class="close">X</span>
        <button @click="completeDeleteDesign" class="button rounded">Confirm delete</button>
    </div>
    <div class="confirmationBox" v-if="garmentToRemove !== false">
        <span @click="garmentToRemove = false" class="close">X</span>
        <button @click="completeRemoveGarment" class="button rounded">Confirm delete</button>
    </div>
    <div class="basket-footer grid-x grid-padding-x">
      <div class="cell small-6 bg-white">
        <div class="pl-3 pt-1">
          <span class="footer-price-title">
            Total:
          </span>
          <span class="footer-price">
            {{ totals.exVat | priceInPounds | currency }}
          </span>
          <span class="footer-vat-notice">
            VAT excl
          </span>
        </div>
      </div>
      <div class="cell small-6 relative">
        <div class="footer-button pt-2">
          <router-link to="/checkout">
            <img
              class="mr-2"
              width="20px"
              src="../../assets/icons/shopping_cart_transparent.svg"
              alt="" />
            <span class="footer-button-title text-white">Checkout</span>
            <span class="footer-button-text text-white">
                {{ timeLeft }} Left - Free delivery
              </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import positionImage from '@/components/basket/PositionImage.vue';
import SelectedGarment from '@/components/SelectedGarment.vue';
import axios from '../../axios';

export default {
  name: 'BasketNarrow',
  data() {
    return {
      designs: [],
      garments: [],
      totals: {},
      selectedProduct: 0,
      designToDelete: false,
      garmentToRemove: false,
      timeLeft: 0,
    };
  },
  components: {
    appPositionImage: positionImage,
    appSelectedGarment: SelectedGarment,
  },
  methods: {
    getTimeLeft() {
      const start = new Date();
      start.setHours(16, 0, 0); // 4pm
      const now = new Date();
      if (now > start) { // too late, go to tomorrow
        start.setDate(start.getDate() + 1);
      }
      const remain = ((start - now) / 1000);
      const hours = Math.floor(remain / 3600);
      const minutes = Math.floor((remain - (hours * 3600)) / 60);
      const timeLeft = `${hours}Hr ${minutes}Mn`;
      this.timeLeft = timeLeft;
    },
    selectGarment(index) {
      const garment = this.garments[index];
      this.selectedProduct = garment.garmentid;
    },
    closeSelectedGarment() {
      this.selectedProduct = 0;
    },
    updateBasket() {
      const postData = {
        garments: this.garments,
        basket: this.$store.state.basketId,
      };
      axios.post('/baskets/updateBasket.json', postData)
        .then((response) => {
          this.designs = response.data.designs;
          this.garments = response.data.garments;
          this.totals = response.data.totals;
          this.showQuantityBox = false;
          this.showSizeBox = false;
        });
    },
    editDesign(id) {
      this.$router.push(`/edit-design/${id}`);
    },
    deleteDesign(index) {
      this.designToDelete = index;
    },
    completeDeleteDesign() {
      const design = this.designs[this.designToDelete];
      const designId = design.id;
      axios.get(`/designs/delete/${designId}.json`)
        .then((response) => {
          if (response.data === true) {
            this.designs.splice(this.designToDelete, 1);
            this.designToDelete = false;
          }
        });
    },
    removeGarment(index) {
      this.garmentToRemove = index;
    },
    completeRemoveGarment() {
      const garment = this.garments[this.garmentToRemove];
      const itemId = garment.basket_item_id;
      axios.get(`/baskets/removeGarment/${itemId}.json`)
        .then((response) => {
          if (response.data === true) {
            this.garments.splice(this.garmentToRemove, 1);
            this.garmentToRemove = false;
            this.getBasket();
          }
        });
    },
    getBasket() {
      axios.get(`/baskets/get/${this.$store.state.basketId}.json`)
        .then((response) => {
          this.designs = response.data.designs;
          this.garments = response.data.garments;
          this.totals = response.data.totals;
        });
    },
  },
  mounted() {
    this.getBasket();
    this.getTimeLeft();
    this.timer = window.setInterval(() => {
      this.getTimeLeft();
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
  .basket-header {
    font-family: 'rennersemi';
    text-transform: uppercase;
    font-size: 22px;
    margin: 0;
  }
  .basket-subheading {
    color: #29287c;
    font-size: 14px;
    font-family: 'rennersemi';
  }
  .basket-image-container {
    height: 45px;
  }
  .basket-type-image {
    width: 60%;
    max-height: 40px;
  }
  .garment-table-heading {
    text-transform: uppercase;
    color: #29287c;
    font-size: 10px;
  }
  .garment-table-content {
    color: #29287c;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    display: inline-block;
    font-size: 11px;
    width: 96%;

    &.price {
      color: #1dd699;
    }
  }
  .fixed-height-text-box {
    height: 20px;
  }
  .link {
    cursor: pointer;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  .basket-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    min-height: 55px;
  }
</style>
